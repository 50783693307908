import { encode } from 'urlencode';
import Inquiry from '../type/Inquiry';

const removeAsterisks = (text: string) => text.replace(/\*{2,}(.+?)\*{2,}/g, '$1');

export default function createOutput(inquiry: Inquiry, advice: string) {
  const markdownRemovedAdvice = removeAsterisks(advice);

  const paragraphs = markdownRemovedAdvice.split('\n\n\n');

  const first = paragraphs[0];
  const second = paragraphs[1] || '';
  const third = paragraphs[2] || '';
  const truncatedAdvice = [first, second, third].filter(Boolean).join('\n\n');

  const kinLink = inquiry.url;
  const nokeLink = `https://noke.ai?utm_source=naver&utm_medium=organic&utm_campaign=지식인&naverInquiry=${encode(inquiry.toString())}`;
  const finalAdvice = `${truncatedAdvice}

추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 변호사 선임이나 사건을 맡기실 필요 없습니다. 법률사무소가 아니며 광고도 아닙니다.

2. 100% 무료이며 계속 무료입니다.

3. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

4. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.

무료법률상담 AI변호사 AI법률상담 민사법률상담 형사법률상담 형량예측 법령정보 판례찾기 법률상담
`;

  return {
    kinLink, nokeLink, finalAdvice,
  };
}
