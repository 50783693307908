import Inquiry from '../type/Inquiry';
import bokeAPI from './bokeAPI';

export default async function postConsultation(inquiry: Inquiry): Promise<string> {
  const { data } = await bokeAPI.post('/naver-kin/consultations', {
    inquiryContent: inquiry.toString(),
  });

  return data;
}
