import { useCallback, useState } from 'react';
import pkg from 'bluebird';

import { useRecoilState, useResetRecoilState } from 'recoil';
import crawlInquiry from '../service/crawlInquiry';
import createOutput from '../service/createOutput';
import postConsultation from '../service/postConsultation';
import crawlOutputState from '../store/CrawlOutputState';

const { Promise: Bluebird } = pkg;

export default function useNaverCrawl() {
  const [crawlOutput, setCrawlOutput] = useRecoilState(crawlOutputState);
  const initializeCrawlOutput = useResetRecoilState(crawlOutputState);

  const [isLoading, setIsLoading] = useState(false);

  const naverCrawl = async (url: string, naverCookie: string) => {
    const inquiry = await crawlInquiry(url, naverCookie);
    console.log(`** 크롤링 결과\n\n질문: ${inquiry.title}\n\n내용: ${inquiry.content}`);

    const advice = await postConsultation(inquiry);
    console.log(`** 조언: ${advice}`);

    const {
      kinLink, nokeLink, finalAdvice,
    } = createOutput(inquiry, advice);

    return {
      kinLink,
      nokeLink,
      finalAdvice,
    };
  };

  const consultAllUrl = useCallback(async (urlList: string[], naverCookie: string) => {
    setIsLoading(true);

    const output = await Bluebird.mapSeries(urlList, async (url) => naverCrawl(url, naverCookie));

    setCrawlOutput(output);
    setIsLoading(false);
  }, [setCrawlOutput]);

  const copyNokeLink = useCallback(async (index: number) => {
    await navigator.clipboard.writeText(crawlOutput[index].nokeLink);
  }, [crawlOutput]);
  const copyFinalAdvice = useCallback(async (index: number) => {
    await navigator.clipboard.writeText(crawlOutput[index].finalAdvice);
  }, [crawlOutput]);

  return {
    consultAllUrl,
    naverCrawl,
    crawlOutput,
    isLoading,
    copyNokeLink,
    copyFinalAdvice,
    initializeCrawlOutput,
  };
}
