import { convert } from 'html-to-text';

import Inquiry from '../type/Inquiry';
import bokeAPI from './bokeAPI';

export default async function crawlInquiry(url: string, naverCookie: string): Promise<Inquiry> {
  const { data: html } = await bokeAPI.post('/naver-kin/crawl', {
    url,
    naverCookie,
  });

  const text = convert(html, {
    baseElements: {
      selectors: [
        'div.endTitleSection',
        'div.questionDetail',
      ],
    },
  });

  const [title, ...rest] = text.split('\n');
  const content = rest.join('\n');

  return new Inquiry(url, title, content);
}
